import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-dots-trigger-list-item',
    templateUrl: './dots-trigger-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotsTriggerListItemComponent {
    preventDefault(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    }
}
