<bazis-button
    class="bazis-dropdown__trigger"
    (click)="preventDefault($event)"
>
    <bazis-icon
        slot="icon-only"
        src="/assets/icons/other/menu-dots-vertical.svg"
        size="s"
    ></bazis-icon>
</bazis-button>
