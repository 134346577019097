<div
    class="bh-contents"
    [ngClass]="[
        'ua-' + deviceService.browser,
        'os-' + deviceService.os,
        'dt-' + deviceService.deviceType
    ]"
>
    <router-outlet></router-outlet>
    <bazis-modals></bazis-modals>
    <bazis-alert></bazis-alert>
    <bazis-context-menus></bazis-context-menus>
    <bazis-toast></bazis-toast>
    <bazis-global-loader></bazis-global-loader>
</div>
