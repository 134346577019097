import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-single-item-mobile',
    templateUrl: './single-item-mobile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleItemMobileComponent {
    @Input() hasActions = false;

    isExpanded = false;

    isShowActions = false;

    toggleCard(e) {
        e.stopPropagation();
        this.isExpanded = !this.isExpanded;
    }

    showDropdown(e) {
        e.stopPropagation();
        this.isShowActions = true;
    }

    hideDropdown(e) {
        this.isShowActions = false;
    }
}
