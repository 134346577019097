import { Injectable } from '@angular/core';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { pairwise, shareReplay, Subject, withLatestFrom } from 'rxjs';
import { ROLE, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { catchError, debounceTime, filter, map, startWith, tap } from 'rxjs/operators';

const AUTH_PAGE_URL = '/login';

@Injectable({
    providedIn: 'root',
})
export class MpAuthService extends BazisAuthService {
    private _isFirstRedirect = true;

    updateAgencyContract$ = new Subject();

    defaultPageRedirect$ = this.role$.pipe(
        debounceTime(0),
        withLatestFrom(this.user$),
        startWith([null, null]),
        pairwise(),
        filter(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];
            return prevUser?.id !== currentUser?.id;
        }),
        tap(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];
            const regexp = /^\/(faq|about|legal\-documents){1}(\/.*)*$/;
            if (
                currentUser &&
                !currentUser.$snapshot.raw_password &&
                !regexp.test(location.pathname) &&
                currentUser.$snapshot.created_type_auth !== 'cert' &&
                currentUser.id !== prevUser?.id
            ) {
                this.router.navigate(['/login/init-password']);
                this._isFirstRedirect = false;
                return;
            }
            if (location.pathname.indexOf(AUTH_PAGE_URL) > -1 && !currentUser) return;
            this.redirectToDefaultPage(currentRole, currentUser);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    // redirect to default page
    redirectToDefaultPage(role, user) {
        if (location.pathname.indexOf('access-denied') > -1 && !this._isFirstRedirect) {
            this.router.navigate(['/']);
        } else if (location.pathname.indexOf(AUTH_PAGE_URL) > -1) {
            if (user.$snapshot.roles.length === 1) this.router.navigate(['/']);
            else this.router.navigate(['/login/select-role']);
        }
        this._isFirstRedirect = false;
    }

    logoutAndRedirect$() {
        return this.logout$().pipe(
            map((v) => {
                this.redirectToLoginWithReturnPath();
                return 'logout';
            }),
            catchError((e) => {
                this.redirectToLoginWithReturnPath();
                return 'logout';
            }),
        );
    }
}
